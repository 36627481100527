<template>
  <div>
    <TopNavVue/>
    <div>
      <el-row class="cloudDesktop">
        <el-col :span="22" :offset="1" class="wga">
          <h6 class="title">磐石安全网关</h6>
          <p>
            磐石安全网关是磐石云桌面解决方案中自主研发的一款软硬件一体产品，是应用程序交付控制器，用于分析应用程序特定的流量，以智能地分发和优化 Web 应用程序的网络流量并确保其安全。磐石安全网关功能涵盖交换、安全性和保护、服务器场优化以及负载平衡，可实现外网云桌面的访问加速和用户体验的提升，并确保其可用性和安全性。既能为用户提供简单安全的访问体验，也能为管理员提供有效的安全控制功能。
          </p>
          <img src="@/assets/aqwg/a30.jpg" />
        </el-col>
        <el-col :span="22" :offset="1" class="wga" style="margin-top: 4%;">
          <h6 class="title">产品功能</h6>
          <p>
            磐石安全网关提供了强大的接入安全管理策略和行为控制组件，提供安全接入、负载均衡以及防火墙等功能，通过集成策略管理为多种应用场景提供支持，保障接入、应用和数据的安全，满足包括互联网、跨域网络等不同网络环境安全访问的需求，同时通过配置负载均衡等策略，优化网络性能和可靠性，提高用户体验效果。
          </p>
          <img src="@/assets/aqwg/aq1.png" />
        </el-col>
        <el-col :span="22" :offset="1" class="wga" style="margin-top: 4%;">
          <h6 class="title" style="margin-bottom: 20px;">产品特色</h6>
          <div class="dxb" v-for="(item,index) in proFunction" :key="index">
            <div class="imgbox">
              <img :src="item.imgSrc"/>
            </div>
            <h6>{{item.title}}</h6>
            <p>{{item.desc}}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <footerMobileVue/>
  </div>
</template>

<script>
import TopNavVue from '../../../components/topNav.vue'
import footerMobileVue from '../../../components/footerMobile.vue';
export default {
  name:'aqwgMobile',
  components:{footerMobileVue,TopNavVue},
  data(){
    return{
      proFunction:[
        {title:'简便部署',desc:'提供标准配置界面，简化部署，只需将基础信息相关内容进行配置完成后，系统即可正式上线。',imgSrc:require('@/assets/aqwg/a15.png'),},
        {title:'安全接入',desc:'基于互联网以及域用户应用场景，安全网关提供安全接入服务，支持对访问用户进行身份验证，同时支持对访问用户进行身份验证，提供高效的策略管理。',imgSrc:require('@/assets/aqwg/a13.png'),},
        {title:'实时监测',desc:'配合磐石云桌面与控制中枢（控制引擎）实时监听安全网关运行状态，避免因安全网关问题导致用户不能正常访问，发现安全网关运行异常，系统会主动报警。',imgSrc:require('@/assets/aqwg/a17.png'),},
        {title:'宽域访问',desc:'系统提供宽动态网络支持，带宽的支持范围从百兆到万兆不等，系统支持根据用户实例网络情况进行自动优化调整。',imgSrc:require('@/assets/aqwg/a16.png'),},
        {title:'负载均衡',desc:'提升通过互联网进行终端访问的可靠性，系统提供负载均衡功能，对正在访问的关键业务实时监听，提供负载的冗余通道确保访问效率。',imgSrc:require('@/assets/aqwg/a14.png'),},
        {title:'支持堆叠',desc:'在复杂的用户应用场景下，支持多台网关设备堆叠部署，为用户提供更佳的远程访问体验。',imgSrc:require('@/assets/aqwg/a18.png'),},
      ]
    }
  },
  
}
</script>

<style scoped>
  .cloudDesktop{background: #f5f5f5;padding-top: 17%;padding-bottom: 20px;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .wga{}
  .wga p{font-size: 3vw;color: #888;width: 100%;margin: 15px auto;text-indent: 2em;}
  .wga img{width: 100%;}
  .dxb{width: 100%;margin-bottom: 15px;padding-bottom: 15px; box-shadow: 0px 0px 10px #ccc;}
  .imgbox{padding-top: 10px;}
  .imgbox img{width: 80px;display: block;margin: 10px auto;}
  .dxb h6{font-size: 4vw;color: #333;display: block;margin-top: 10px;margin-bottom: 5px;text-align: center;}
  .dxb p{font-size: 3vw;color: #888;display: block;width: 80%;margin: 15px auto 0px;text-align: justify;text-indent: 2em;}
</style>